var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"text-center",staticStyle:{"margin":"0px"},attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"page-info invisible",attrs:{"id":"upper-text","col":"","lg":"8","md":"10","cols":"11"}},_vm._l((_vm.textUpper),function(item){return _c('p',{key:item.info[0].text,staticClass:"tepa-p",domProps:{"innerHTML":_vm._s(item.info[0].text)}})}),0),_c('b-col',{staticClass:"invisible",attrs:{"id":"swiper","lg":"9","md":"10","cols":"11"}},[(_vm.localImgSwiper.length > 0)?_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.localImgSwiper),function(item,index){return _c('swiper-slide',{key:index,style:(("padding: 0 " + (_vm.isMobile ? 0 : 67) + "px; align-items: center; display: flex;justify-content: center;"))},[_c('div',{staticClass:"img-swiper",style:({
              height: ((620) + "px"),
              width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
              backgroundImage:
                "linear-gradient(\n                        180deg,\n                        rgba(0, 0, 0, 0),\n                        rgba(0, 0, 0, 0)\n                      ),url(" +
                item.img.url +
                ')',
            })})])}),_c('div',{staticClass:"swiper-button-prev swiper-button-white",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next swiper-button-white",attrs:{"slot":"button-next"},slot:"button-next"})],2):_vm._e()],1),_c('b-col',{staticClass:"invisible",staticStyle:{"margin":"50px 0 54px"},attrs:{"id":"lower-text","col":"","lg":"8","md":"10","cols":"11"}},_vm._l((_vm.textLower),function(item){return _c('p',{key:item.info[0].text,staticClass:"tepa-p",domProps:{"innerHTML":_vm._s(item.info[0].text)}})}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }