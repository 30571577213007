<template>
  <div>
    <b-row align-h="center" class="text-center" style="margin: 0px">
      <b-col
        id="upper-text"
        col
        lg="8"
        md="10"
        cols="11"
        class="page-info invisible"
      >
        <p
          class="tepa-p"
          v-for="item in textUpper"
          :key="item.info[0].text"
          v-html="item.info[0].text"
        ></p>
      </b-col>
      <b-col id="swiper" class="invisible" lg="9" md="10" cols="11">
        <swiper
          v-if="localImgSwiper.length > 0"
          class="swiper"
          :options="swiperOption"
        >
          <swiper-slide
            v-for="(item, index) in localImgSwiper"
            :key="index"
            :style="`padding: 0 ${
              isMobile ? 0 : 67
            }px; align-items: center; display: flex;justify-content: center;`"
          >
            <div
              class="img-swiper"
              :style="{
                height: `${620}px`,
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
                backgroundImage:
                  `linear-gradient(
                          180deg,
                          rgba(0, 0, 0, 0),
                          rgba(0, 0, 0, 0)
                        ),url(` +
                  item.img.url +
                  ')',
              }"
            />
          </swiper-slide>
          <div
            class="swiper-button-prev swiper-button-white"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next swiper-button-white"
            slot="button-next"
          ></div>
        </swiper>
      </b-col>
      <b-col
        id="lower-text"
        class="invisible"
        col
        lg="8"
        md="10"
        cols="11"
        style="margin: 50px 0 54px"
      >
        <p
          class="tepa-p"
          v-for="item in textLower"
          :key="item.info[0].text"
          v-html="item.info[0].text"
        ></p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import $ from "jquery";
import "swiper/css/swiper.css";
export default {
  props: ["textUpper", "textLower", "imgSwiper"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      localImgSwiper: [],
      screenSize: null,
      swiperOption: {
        // Enable lazy loading
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {
    isMobile() {
      return this.screenSize <= 991;
    },
  },
  created() {},
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.localImgSwiper = this.imgSwiper;
    $(`#upper-text`)
      .addClass("animate__animated animate__fadeInUp")
      .removeClass("invisible");
    this.setAnimation();
    $(document).scroll(this.setAnimation);
    // this.group = false;
    // if (this.group) {
    //   let temp = [];
    //   for (let i = 0; i < this.imgSwiper.length; i++) {
    //     temp.push([this.imgSwiper[i], this.imgSwiper[i + 1]]);
    //     i += 1;
    //     console.log(i);
    //   }
    //   this.localImgSwiper = temp;
    // }
  },
  methods: {
    setAnimation() {
      const height = $(window).scrollTop() + $(window).height();
      if ($(`#lower-text`).length > 0) {
        if (height > $(`#lower-text`).offset().top) {
          $(`#lower-text`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
        }
        if (height > $(`#swiper`).offset().top) {
          $(`#swiper`)
            .addClass("animate__animated animate__fadeInUp")
            .removeClass("invisible");
        }
      }
    },
    onResize() {
      this.screenSize =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    },
  },
};
</script>
<style scoped>
>>> .swiper-wrapper {
  align-items: center;
}
.page-info {
  margin: 60px 0 50px;
}
@media only screen and (max-width: 992px) {
  .page-info {
    margin: 30px 0 30px;
  }
}
</style>
<style lang="scss" scoped>
.tepa-p {
  font-family: Open Sans;
  margin-bottom: 20px !important;
}
.swiper-button-prev {
  background-image: url("~@/assets/prev-icon.svg") !important;
  background-repeat: no-repeat;
}
.swiper-button-next {
  background-image: url("~@/assets/next-icon.svg") !important;
  background-repeat: no-repeat;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: unset;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: unset;
}
@media only screen and (max-width: 500px) {
  .swiper-button-prev {
    background-image: url("~@/assets/prev-icon-white.svg") !important;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    background-image: url("~@/assets/next-icon-white.svg") !important;
    background-repeat: no-repeat;
  }
  .img-swiper {
    display: flex;
    justify-content: center;
    height: 214px !important;
    align-items: center;
    text-align: center;
    font-weight: bold;
    background-position: 50% 0%;
    background-size: cover;
    font-size: 40px;
    background-color: #fff;
  }
  .img-fluid {
    width: 100%;
    background-size: cover;
  }
}
</style>